@use "../settings/global" as *;
@use "../tools" as *;

.text-image__headline {
    font-family: $font-family;
    font-size: 2.4rem;
    font-weight: 700;
}

.text-image__media {
    display: flex;
    // justify-content: center;
    
    @include mq(0, $bp-mobilenav) {
        padding-top: $vertical-spacing * 2;
    }
}

.text-image__image {
    width: 100%;
    object-fit: contain;
    border-radius: 20px;

    @include mq(0, $bp-mobilenav) {
        max-width: 42rem;
    }
}
