@use "../settings/global" as *;
@use "../tools/mixins" as *;
@use "../tools/mq" as *;

.banner-container {
    background-color: #fff;
    display: none;
    @include mq($bp-mobilenav) {
        display: block;
        order: -1;
    }
}

.banner {
    max-width: $max-width-large;
    margin-left: auto;
    margin-right: auto;
    padding: $spacing $gutter;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;

    .contactinfo__link {
        font-size: 1.4rem;
        font-weight: 500;
        align-items: center;

        &:not(:first-child) {
            margin-left: $spacing;

            @include mq(md) {
                margin-left: $spacing;
            }
        }

        svg {
            max-height: 1.4rem;
            margin-right: $spacing-small;
        }
    }

    .contactinfo__info {
        @include mq(0, md) {
            display: none;
        }
    }
}
