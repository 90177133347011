/// BASICS

// assets paths (see functions assets helper)
// $assets-image-path:    '/front/images';
// $assets-font-path:    '/front/fonts';

// defaults
$vertical-spacing:  1.6rem;
$spacing:           $vertical-spacing;
$spacing-small:     calc($spacing / 2);
$spacing-large:     calc($spacing * 2);

$gutter:            $spacing;
$max-width-small:   100rem;
$max-width:         120rem;
$max-width-large:   120rem;
$columns:           12;

/// Breakpoints - used by the grid and media query manager
/// @type Map
/// @prop {size}: [min-width] - breakpoint name and minimum width for media queries
$breakpoints: (
'xs': 0,
'sm': 420px,
'md': 769px,
'lg': 960px,
'xl': 1200px,
'xxl': 1440px
);

/// COLORS
$offwhite:          #F1F1F1;
$white:             #FFFFFF;
  $gray-lightest:     #dfdfdf;
$gray-lighter:      #A1AFB8;
$gray-light:        #ababab;
$gray:              #8b8b8b;
$gray-dark:         #606060;
$gray-darker:       #3c3c3c;
$gray-darkest:      #292929;
$black:             #000000;

// color names
$color-primary:               #0067a8;
$color-primary-dark:          #445F72;
$color-primary-darkest:       #263944;
$color-primary-light:         #c8e6f9;
$color-primary-lightest:      #F7FBFE;

$color-secondary:             #fbab28;
$color-secondary-dark:        #9e6b18;
$color-secondary-darkest:     #503405;
$color-secondary-light:       #FCE1D5;
$color-secondary-lightest:    #fff8f5;

/*$color-tertiary:           $blue;*/

$color-subsidiary:            $gray;
$color-subsidiary-light:      $gray-light;
$color-subsidiary-lighter:    $gray-lighter;
$color-subsidiary-lightest:   $gray-lightest;
$color-subsidiary-dark:       $gray-dark;
$color-subsidiary-darker:     $gray-darker;
$color-subsidiary-darkest:    $gray-darkest;

$color-text:         $color-primary-darkest;
$color-text-light:   $color-subsidiary-lighter;
$color-border:       $color-subsidiary-lighter;

$color-success:             #2DCB48;
$color-success-lightest:    #C5E9CB;
$color-warning:             #F5A623;
$color-warning-lightest:    #F6E5C9;
$color-danger:              #ED1C24;
$color-danger-lightest:     #efa5aa;
$color-yellow:              #FFB800;

// social media
$color-facebook:    #3C5A99;
$color-twitter:     #1DA1F2;
$color-linkedin:    #0077b5;
$color-whatsapp:    #25D366;
$color-youtube:     #FF0000;
$color-pinterest:   #E60023;

$gradient-instagram: radial-gradient(circle farthest-corner at 32% 106%, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), linear-gradient(135deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);

/// TYPOGRAPHY
// fonts
$font-primary:   'Comfortaa', sans-serif;
$font-secondary: 'Comfortaa', sans-serif;

// default
$font-family:             $font-primary;
$font-family-secondary:   $font-secondary;

$font-size:            1.6rem;
$font-size-small:      1.4rem; 
$font-size-large:      1.8rem;
$font-lineheight:      1.6;
$font-weight:          400;

$lineheight-heading:   1.6;
$lineheight-body:      $font-lineheight;

/// DEFAULT STYLES

//header & nav
$bp-mobilenav: lg;
$mobilenav-header-height: 7rem;

$border-radius: 1.6rem;
$box-shadow:    0;

$gradient-overlay:       linear-gradient(180deg, rgba(8,31,45,1) 0%, rgba(8,31,45,0.44) 41.93%, rgba(8,31,45,0.18) 71.61%, rgba(8,31,45,0) 99.92%);
$gradient-overlay-dark:  linear-gradient(rgba(234,110,75,.6));
//$gradient-overlay-dark:  linear-gradient(180deg, rgba(234,110,75,.5) 30%, rgba(234,110,75,0.44) 30%, rgba(234,110,75,0.18) 30%, rgba(234,110,75,0) 30%);
// $gradient-overlay: linear gradient(0deg, rgba(46,40,38,0) 0%, rgba(46,40,38,0.87) 60.43%, #2E2826 100%);
