@use "../settings/global" as *;
@use "../tools/" as *;

.service {
    padding-top: $vertical-spacing;
    padding-bottom: $vertical-spacing;
    height: 100%;
}

.service__content {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    &:hover,
    &:focus-within {
        &::before {
            background-color: $color-primary;
            opacity: 0.9;
            z-index: 1;
            transition: all 0.1s ease-in;
        }

        .service__image {
            transform: scale(1.05);
        }
    }
}

.service__body {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
}

.service__link {
    display: block;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    // margin-top: auto;
}

.service__media {
    overflow: hidden;
    order: -1;
    margin-bottom: $vertical-spacing;
    border-radius: $border-radius;
}

.service__image {
    border-radius: $border-radius;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
}
