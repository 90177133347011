@use "../settings/global" as *;
@use "../tools" as *;

.hero-container {
    position: relative;
    padding-top: $vertical-spacing * 2;
    padding-bottom: $vertical-spacing * 2;
    background-color: #0067a8;

    @include mq($bp-mobilenav) {
        padding-top: $vertical-spacing * 3;
        padding-bottom: $vertical-spacing * 2;
        margin-bottom: $vertical-spacing;

        &.has-pattern {
            padding-top: calc(100vw * 0.0625);
            padding-bottom: calc(100vw * 0.0625);
        }
    }

    @include mq(0, md) {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $color-primary-darkest;
            opacity: 0.8;
            z-index: 2;
        }
    }
    // background-color: $color-subsidiary-darker;
}

.hero {
    max-width: $max-width;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include mq($bp-mobilenav) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    &.hero--center {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            background-image: url("/assets/svg/header-circles.svg");
            background-size: contain;
            background-repeat: no-repeat;
            top: 0;
            right: 0;
            bottom: 0;
            left: 70%;
            opacity: 0.75;
            //background-repeat: no-repeat;
            //background-position: right;
            //opacity: 80%;
        //text-align: center;
        }
    }

    &.hero--center > .hero__body {
        flex: unset;
        width: 50%;
    }

}

.hero__body {
    position: relative;
    padding: $gutter;
    color: $white;
    font-size: 2rem;
    font-weight: 400;
    line-height: 2;
    z-index: 2;

    > :last-child {
        margin-bottom: 0;
    }

    @include mq(md, $bp-mobilenav) {
        width: 55%;
    }

    @include mq($bp-mobilenav) {
        flex: 1 1 50%;
    }
}

.hero__headline {
    position: relative;
    margin-bottom: $vertical-spacing * 2;
    padding-top: $vertical-spacing * 2;
    display: block;
    font-family: $font-family-secondary;
    font-size: 3.2rem;
    font-weight: 500;
    color: $white;

    // @include mq(640) {
    //     .hero-body:first-child:not(:last-child) & {
    //         width: 140%;
    //     }
    // }

    @include mq(md) {
        font-size: 6.4rem;
        font-weight: 500;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 12rem;
        border-top: 4px solid $color-primary;

        .hero--center & {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

// .hero__tagline {
//     display: block;
//     font-family: $font-family-secondary;
//     font-size: 1.6rem;
//     font-weight: 500;
//     font-style: italic;
//     text-transform: uppercase;
//     line-height: 1.1;
//     letter-spacing: 0.05em;
//     color: $white;

//     margin-bottom: $vertical-spacing;

//     &::after {
//         content: "";
//         display: inline-block;
//         vertical-align: middle;
//         position: relative;
//         top: -1px;
//         max-width: 20%;
//         width: 100%;
//         height: 2px;
//         background-color: $color-subsidiary-dark;
//     }
// }

.hero__media {
    @include mq(640) {
        flex: 0 0 auto;
        align-self: flex-start;
        margin: 0;
    }

    @include mq(lg) {
        width: 33.334%;
        // display: block;
        margin: $gutter;
        padding: $gutter;
        z-index: 0;
    }

    .has-pattern & {
        @include mq(lg) {
            width: 41.667%;
            margin-left: 8.333%;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 40%;
            mask: url("/assets/svg/hero-pattern.svg");
            mask-repeat: no-repeat;
            mask-size: cover;
            mask-position: center;
            mask-size: auto 100%;
            mask-position: left center;
            background-color: #0067a8;
            z-index: 1;

            @include mq(0, sm) {
                left: -12%;
                width: 100%;
            }

            @include mq(550) {
                left: 50%;
            }
        }
    }
}

.hero__image {
    .has-pattern & {
        display: block;
        width: 50%;
        height: 100%;
        object-fit: cover;
        object-position: center center;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        z-index: 0;

        @include mq(0, sm) {
            left: 0;
            width: 100%;
        }
    }

    .has-vector & {
        position: absolute;
        top: $spacing;
        right: 0;
        height: calc(90% - #{$spacing-large});
        z-index: 0;
        border-radius: 20px;
        opacity: 80%;

        @include mq(0, md) {
            height: calc(100% - #{$spacing-large});
        }

        @include mq(md) {
            left: 65%;
        }

        @include mq(lg) {
            opacity: unset;
        }
    }
}
