@use "../settings/global" as *;
@use "../tools" as *;

.subnav__toggle {
    border: 0;
    background: none;

    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;

    background: transparent;

    height: 4.2rem;
    line-height: 4.2rem;
    width: 4.2rem;
    padding: 0;

    cursor: pointer;

    font-size: 2.1rem;
    color: #0067a8;
    transition: color 0.1s, background-color 0.1s;

    @include mq($bp-mobilenav) {
        display: none;
    }

    &::after {
        content: "";
        display: block;
        mask: url("/assets/icons/chevron-down.svg");
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center center;
        background-color: #0067a8;

        width: 1.2rem;
        height: 1.2rem;

        margin-top: 5px;
        margin-left: auto;
        margin-right: auto;
    }

    &:hover {
        color: #c8e6f9;
        background-color: $color-secondary-lightest;
    }

    &:active {
        color: #c8e6f9;
        background-color: transparent;
    }

    .subnav__item > & {
        height: 4.2rem;
        line-height: 4.2rem;
        width: 4.2rem;

        &:hover {
            color: #c8e6f9;
            background-color: rgba($color-secondary-light, 0.1);
        }
    }

    .is-opened > & {
        &::before {
            transform: rotate(180deg);
        }
    }
}

.subnav {
    list-style: none;
    margin: 0;

    @include mq(0, $bp-mobilenav) {
        display: none;
        //padding-top: $spacing;
        //padding-bottom: $spacing;
        background-color: rgba($color-secondary-lightest, 0.5);
        border-top: 1px solid rgba($color-secondary-light, 0.4);
        border-bottom: 1px solid rgba($color-secondary-light, 0.4);

        & .subnav {
            border-top: 0;
            border-bottom: 0;
        }

        &[aria-hidden="false"] {
            display: block;
        }
    }

    @include mq($bp-mobilenav) {
        display: block;
        margin-top: 0.3em;
        box-shadow: 0 0.3rem 0.4rem rgba($color-primary-darkest, 0.3);

        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: $white;
        z-index: 99;
        padding: 0;
        text-align: left;
        // box-shadow: 0 2px 6px 0 rgba($color-subsidiary-darkest, 0.8), 0 1px 12px 0 rgba($color-subsidiary-darkest, 0.3);
        border: 0;

        min-width: 100%;
        transition: opacity 0.1s;
        border-radius: $border-radius;

        //show on hover, spacing between parent fix
        &::before {
            content: "";
            display: block;
            height: 0.5em;
            left: 0;
            right: 0;
            position: absolute;
            top: -0.5em;
        }

        &.is-right {
            left: auto;
            right: 0;
        }

        &[aria-hidden="false"] {
            visibility: visible;
            opacity: 1;
        }

        .subnav {
            left: 100%;
            right: auto;
            top: 0;
            margin-top: 0;
        }

        &.subnav--left .subnav {
            left: auto;
            right: 100%;
        }

        .mainnav__item:last-child & .subnav {
            right: 100%;
            left: auto;
        }
    }
}

.subnav__item {
    position: relative;
    float: none;
    display: block;

    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;

    @include mq($bp-mobilenav) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        white-space: nowrap;

        // & + & {
        //     border-top: 1px solid $color-subsidiary-dark;
        // }

        &.is-active > .subnav__button {
            color: $color-secondary;
            // border-bottom: 1px solid $color-secondary;
        }
    }
}

.subnav__button {
    position: relative;
    overflow: hidden;
    display: block;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: $spacing + 4.2rem;
    padding-right: $spacing;

    font-family: $font-family-secondary;
    font-size: $font-size;
    font-weight: 500;
    color: $color-text;
    text-decoration: none;
    text-align: left;

    transition: color 0.1s, background-color 0.1s;

    &:hover,
    &:focus {
        color: $color-text;
        background-color: rgba($color-secondary-lightest, 1);
        text-decoration: none;
    }

    &:active {
        color: $color-text;
        background-color: transparent;
    }

    @include mq(0, $bp-mobilenav) {
        .subnav .subnav & {
            padding-right: $spacing * 2;
        }

        &::after {
            display: inline-block;
            width: $spacing;
            text-align: right;
            content: "-";
            color: rgba($color-secondary-light, 0.4);
        }
    }

    /*
    .subnav__item.is-active > & {
        color: $color-secondary;

        &:hover, &:focus {
            color: $color-text;
        }
    }
    */

    @include mq($bp-mobilenav) {
        display: block;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 3rem;
        padding-right: 3rem;

        font-family: $font-family-secondary;
        color: $color-text;

        &:hover,
        &:focus {
            color: $color-secondary;
            background-color: transparent;

            &::before {
                left: 1rem !important;
            }
        }

        &:active {
            color: $white;
            // background-color: $color-subsidiary-dark;
        }
    }
}
