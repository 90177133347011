@use "../settings/global" as *;
@use "../tools" as *;

.credits-container {
    background-color: $color-primary-light;
}

.credits {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding: $spacing-large $gutter;
    // padding-top: 0;

    font-size: $font-size-small;
    text-align: center;

    @include mq(md) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        // padding-top: $spacing-large;
        padding-bottom: $spacing-large;

        text-align: left;
    }
}

.credits__start {
    padding-top: $spacing * 2;
    padding-bottom: $spacing * 2;
}

.credits__end {
    padding-top: $spacing * 2;
    padding-bottom: $spacing * 2;

    img {
        margin-left: 4px;
    }
}

.creditsnav {
    @include mq(md) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
}

.creditsnav__media {
    margin-bottom: $spacing;

    @include mq(md) {
        margin-right: $spacing;
        margin-bottom: 0;
    }
}

.creditsnav__body {
}

.creditsnav__line {
}

.creditsnav__br {
    display: block;
    height: 0;

    @include mq(620) {
        display: none;
    }
}

.creditsnav__list {
    display: block;
    list-style: none;
    margin-top: $spacing;
    margin-bottom: 0;
    margin-left: 0;

    @include mq(620) {
        margin-top: 0;
    }
}

.creditsnav__item {
    display: block;
    color: $color-primary;

    & + & {
        margin-top: 0.4rem;
    }

    @include mq(620) {
        display: inline-block;

        & + & {
            margin-top: 0;

            &::before {
                display: inline-block;
                content: "/";
                color: $color-text;
                margin-right: 0.4rem;
                margin-left: 0.4rem;
            }
        }
    }
}

.creditsnav__link {
    font-weight: 400;
    text-decoration: underline;
    color: $color-primary;

    &:hover,
    &:focus {
        color: $white;
        text-decoration: none;
    }

    &:active {
        color: $color-primary;
        text-decoration: none;
    }
}
