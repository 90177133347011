@use "../settings/global" as *;
@use "../tools" as *;

.video__media {
    padding-bottom: $vertical-spacing;
}

.video__iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;

    &::after {
        content: "";
        display: block;
        padding-top: 56.25%;
    }
}

.video__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// .video__iframe {
//     width: 100%;
//     object-fit: cover;
//     margin-bottom: $vertical-spacing;
// }
