@use "../settings/global" as *;
@use "../tools" as *;

.site-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    // overflow-x: hidden;
    // overflow-y: initial;
}

.site-header {
    flex: none;
    background-color: $white;
    position: relative;
    z-index: 99;
    // overflow-x: hidden;

    @include mq(0, $bp-mobilenav) {
        height: $mobilenav-header-height;
    }

    &::after {
        position: fixed;
        display: block;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($color-primary-darkest, 0.9);
        z-index: -99;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s;
    }

    .has-open-navigation &::after {
        opacity: 0.9;
        visibility: visible;
    }
}

.site-footer {
    flex: none;
    // border-top: 1px solid $color-subsidiary-dark;
}

.site-main {
    position: relative;
    flex: 1 0 auto;
    padding-bottom: $vertical-spacing;
    // background-color: $offwhite;
}
