@use "../settings/global" as *;
@use "../tools" as *;

@mixin containerBackground() {
    margin-top: 0;
    margin-bottom: 0;

    padding-top: $vertical-spacing * 3;
    padding-bottom: $vertical-spacing * 3;

    @include mq(md) {
        padding-top: $vertical-spacing * 6;
        padding-bottom: $vertical-spacing * 6;
    }

    & + & {
        padding-top: 0;
    }

    &:last-child {
        margin-bottom: 0 !important;
    }

    &.container--small {
        padding-top: $vertical-spacing;
        padding-bottom: $vertical-spacing;

        @include mq(lg) {
            padding-top: $vertical-spacing * 2;
            padding-bottom: $vertical-spacing * 2;
        }
    }

    &.container--large {
        @include mq(lg) {
            padding-top: $vertical-spacing * 6;
            padding-bottom: $vertical-spacing * 6;
        }
    }

    .site-main > &:last-child {
        margin-bottom: -$vertical-spacing !important;
    }
}

.container {
    margin-top: $vertical-spacing * 3;
    margin-bottom: $vertical-spacing;

    @include mq(md) {
        margin-top: $vertical-spacing * 6;
        margin-bottom: $vertical-spacing * 5;
    }

    // .hero-container + & {
    //     margin-top: $vertical-spacing;
    // }
}

.container--small {
    @include mq(md) {
        margin-top: $vertical-spacing * 2;
        margin-bottom: $vertical-spacing;
    }
}

.container--large {
    @include mq(lg) {
        margin-top: $vertical-spacing * 6;
        margin-bottom: $vertical-spacing * 5;
    }
}

.container--primary {
    background-color: $color-primary;
    @include containerBackground();
}

.container--secondary {
    background-color: $color-secondary;
    @include containerBackground();
}

.container--light {
    background-color: $color-primary-lightest;
    @include containerBackground();
}

.container--dark {
    background-color: $color-subsidiary-darker;
    @include containerBackground();
}

.container--darkest {
    background-color: $color-subsidiary-darkest;
    @include containerBackground();
}

.container--offwhite {
    background-color: $offwhite;
    @include containerBackground();
}
