@use '../settings/global' as *;
@use '../tools' as *;

.topsubnav__toggle {    
    border: 0;
    background: none;

    z-index: 1;
    position: relative;
    top: 1px;

    height: 2rem;
    line-height: 2rem;
    width: 2rem;
    padding: 0;

    cursor: pointer;

    font-size: $font-size;
    color: $color-text;
    transition: color 0.1s, background-color 0.1s;

    @include mq($bp-mobilenav) {
        display: none;
    }

    // &::before {
    //     display: block;
    //     height: inherit;
    //     width: inherit;
    //     content: '\F35D';
    //     font-family: $mdi-font-name;
    // }

    &:hover, &:focus {
    }

    &:active {
    }

    .is-opened > & {
        &::before {
            transform: rotate(180deg);
        }
    }
}

.topsubnav {
    list-style: none;
    margin: 0;

    @include mq(0, $bp-mobilenav) {
        display: none;
        background-color: rgba($color-primary-darkest, 0.1);

        &[aria-hidden="false"] {
            display: block;
        }
    }

    @include mq($bp-mobilenav) {
        display: block;
        margin-top: 0.3em;
        min-width: 100%;

        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $white;
        z-index: 99;
        padding: 0;
        text-align: left;
        box-shadow: 0 2px 6px 0 rgba($color-primary-darkest,0.12), 0 1px 12px 0 rgba($color-primary-darkest,0.08);
        border-radius: 0;

        transition: opacity 0.2s;

        //show on hover, spacing between parent fix
        &::before {
            content: '';
            display: block;
            height: 0.3em;
            left: 0;
            right: 0;
            position: absolute;
            top: -0.3em;
        }

        &.topsubnav--right {
            left: auto;
            right: 0;
        }

        &[aria-hidden="false"] {
            visibility: visible;
            opacity: 1;
        }

        .topsubnav {
            left: 100%;
            right: auto;
            top: 0;
            margin-top: 0;
        }

        &.topsubnav--left .topsubnav {
            left: auto;
            right: 100%;
        }
    }
}

.topsubnav__item {
    position: relative;
    float: none;
    display: block;
    white-space: nowrap;

    @include mq($bp-mobilenav) {
        & + & {
            border-top: 1px solid $color-primary-lightest;
        }
    }
}

.topsubnav__link {
    display: block;
    padding-top: $spacing-small;
    padding-right: $gutter;
    padding-bottom: $spacing-small;
    padding-left: $gutter *2;

    font-family: $font-family;
    font-size: $font-size-small;
    font-weight: 400;
    color: $white;
    text-decoration: none;
    text-align: left;
    line-height: 1.3;

    transition: color 0.1s, background-color 0.1s;

    &:hover, &:focus {
        color: $white;
        background-color: $color-primary-dark;
    }

    .topsubnav__item.is-active & {
        color: $white;
        background-color: $color-primary-darkest;
    }

    @include mq($bp-mobilenav) {
        display: block;
        padding-top: 0.8rem;
        padding-right: 1rem;
        padding-bottom: 0.8rem;
        padding-left: 1rem;
        color: $color-text;    
        font-size: $font-size-small;
        font-weight: 600;

        &:hover, &:focus {
            color: $color-text;
            background-color: $color-secondary;

            /*&::before {
                left: 1rem;
            }*/
        }

        &:active {
            color: $color-text;
            background-color: $color-secondary-light;
        }

        /*&::before {
            content: '\f142';
            font-family: $mdi-font-name;
            display: block;
            position: absolute;
            left: 0.6rem;
            top: 0.7rem;
            width: 1rem;
            font-size: $font-size;
            font-weight: 400;
            transition: left 0.1s;
        }*/
    }
}
