@use "../settings/global" as *;
@use "../tools" as *;
@use "../elements/type-headings" as *;

.leading {
    font-size: $font-size-large;
    
    &.leading--center {
        text-align: center;
    }
}

.leading__section {
    position: relative;
    margin-bottom: $vertical-spacing * 2;
    padding-top: $vertical-spacing * 2;
    font-size: 3.6rem;
    font-weight: 500;
    color: $color-primary-darkest;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-top: 4px solid $color-primary;
        width: 12rem;
        margin-left: auto;
        margin-right: auto;
    }

    strong {
        font-weight: 800;
        color: $color-primary;
    }

    .contrast-element & {
        color: $white;
    }
}
