@use "../settings/global" as *;
@use "../tools/mq" as *;

.blog {
    padding-top: $vertical-spacing;
    padding-bottom: $vertical-spacing;
    height: 100%;
}

.blog__content {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    &:hover,
    &:focus-within {
        .blog__image {
            transform: scale(1.05);
        }
    }
}

.blog__body {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;

    p:last-child {
        margin-bottom: 0;
    }
}

.blog__headline {
    font-family: $font-family;
    font-size: $font-size-large;
    font-weight: 700;
}

.blog__link {
    display: block;
    // margin-top: auto;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.blog__date {
    margin-bottom: $vertical-spacing;
    color: $color-text-light;
    order: -1;
}

.blog__media {
    margin-bottom: $vertical-spacing;
    order: -1;
    width: 100%;
    overflow: hidden;
    border-radius: $border-radius;
}

.blog__image {
    width: 100%;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
}
