/// Misc

// Selection
::-moz-selection {

}

::selection {

}

// Misc
strong, b {
    font-weight: 600;
}
em, dfn, i {
    font-style: italic;
}
u, ins {
    text-decoration: underline;
}

sub, sup {
    font-size: small;
    position: relative;
    line-height: 0;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
del, s {
    text-decoration: line-through;
}
small {
    font-size: smaller;
}
mark {
}
code {
    vertical-align: middle;
    padding: 0.25em 0.5em;
}
pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word; 
}
abbr[title], acronym[title] {
    cursor: help; 
}
var {
    font-style: italic;
    font-size: smaller;
    font-weight: 600;
}
kbd {
    position: relative;
    top: -2px;
    background-color: transparent;
    border-style: solid;
    border-width: 1px 2px 2px 1px;
    border-color: currentColor;
    border-radius: 2px;
    font-size: smaller;
    padding-left: 2px;
    padding-right: 2px;
}