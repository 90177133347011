@use '../settings/global' as *;

/// Anchor
a {
    color: $color-primary;
    text-decoration: underline;
    font-weight: 600;
    outline-offset: 1px;

    transition: color 0.1s;

    &:not([class]) {
        outline-offset: 0;
    }

    &:hover,
    &:focus {
        color: $color-primary-dark;
    }

    &:active {
        color: $color-primary-darkest;
        text-decoration: none;
    }

    &:focus-visible {
        outline: 2px dashed $color-secondary-light;
        outline-offset: 1px;
        z-index: 1;
    }

    &:visited {}
}