@use '../settings/global' as *;

html {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    height: 100%;
    min-height: 100%;

    font-size: $font-size;
    font-family: $font-family;
    font-weight: $font-weight;
    line-height: $font-lineheight;
    color: $color-text;

    &.no-scroll, 
    &.has-open-navigation {
        height: 100%;
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
}