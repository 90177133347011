@use "../settings/global" as *;
@use "../tools/" as *;

.language {
    display: flex;
    z-index: 1;

    @include mq(0, $bp-mobilenav) {
        padding: 0 $gutter;
    }
}

.language__list {
    margin-bottom: 0;
    display: flex;
    align-items: center;
}
