@use "../settings/global" as *;
@use "../tools/" as *;

$hamburger-layer-height: 0.2rem;
$hamburger-layer-width: 3rem;
$hamburger-layer-spacing: 0.6rem;
$hamburger-layer-border-radius: 0;

.hamburger {
    padding: 0;
    display: inline-block;
    cursor: pointer;

    transition-property: opacity, filter, background-color;
    transition-duration: 0.1s;
    transition-timing-function: linear;

    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    overflow: visible;

    &:hover,
    .mobilenav__toggle:hover & {
        .hamburger__inner {
            &,
            &::before,
            &::after {
            }
        }
    }
}

.hamburger__box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: block;
    position: relative;
}

.hamburger__inner {
    display: block;
    top: 50%;
    margin-top: calc($hamburger-layer-height / -2);

    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: currentColor;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.1s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
}

/*
 * Emphatic Reverse
 */
.hamburger--emphatic-r {
    overflow: hidden;

    .hamburger__inner {
        transition: background-color 0.2s 0.25s ease-in;

        &::before {
            left: 0;
            transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335),
                top 0.05s 0.2s linear,
                left 0.2s 0.25s ease-in;
        }

        &::after {
            top: ($hamburger-layer-height) + ($hamburger-layer-spacing);
            right: 0;
            transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335),
                top 0.05s 0.2s linear,
                right 0.2s 0.25s ease-in;
        }
    }

    .has-open-navigation & {
        .hamburger__inner {
            transition-delay: 0s;
            transition-timing-function: ease-out;
            background-color: transparent;

            &::before {
                left: $hamburger-layer-width * -2;
                top: $hamburger-layer-width * 2;
                transform: translate3d($hamburger-layer-width * 2, $hamburger-layer-width * -2, 0) rotate(-45deg);
                transition: left 0.2s ease-out,
                    top 0.05s 0.2s linear,
                    transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
            }

            &::after {
                right: $hamburger-layer-width * -2;
                top: $hamburger-layer-width * 2;
                transform: translate3d($hamburger-layer-width * -2, $hamburger-layer-width * -2, 0) rotate(45deg);
                transition: right 0.2s ease-out,
                    top 0.05s 0.2s linear,
                    transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
            }
        }
    }
}
