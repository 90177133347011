@use "../settings/global" as *;
@use "../tools/mq" as *;

.card {
    padding-top: $vertical-spacing;
    padding-bottom: $vertical-spacing;
    margin-bottom: $vertical-spacing;
}

.card__content {
    //position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    background-color: transparent;
    text-decoration: none;

    &::before {
        content: "";
        background-color: $color-primary-darkest;
        opacity: 0.3;
        border-radius: $border-radius;
        transition: all 0.2s ease-out;
    }

    &:hover,
    &:focus-within {

        .card__headline {
            text-decoration: underline;
        }

        .button {
            background-color: $color-primary-dark;
            color: $white;
        }

        &::before {
            background-color: $color-primary;
            opacity: 0.9;
            z-index: 1;
            transition: all 0.1s ease-in;
        }

        .card__media {
            transform: scale(1.05);
        }
    }
}

.card__head {
    margin-bottom: $spacing;
}

.card__headline {
    font-size: 2.4rem;
    font-weight: 700;
    color: $color-primary;
    line-height: $lineheight-heading;
    margin-bottom: 0;
}

.card__media {
    flex: 1 1 auto;
    order: -1;
    overflow: hidden;
    border-radius: $border-radius;
    margin-bottom: $spacing;
}

.card__image {
    width: 100%;
    border-radius: $border-radius;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
    overflow: hidden;
}

.card__date {
    margin-bottom: $vertical-spacing;
    color: $color-text-light;
    order: -1;
}

.card-overview {
    box-shadow: .5rem .5rem .5rem $gray-lightest;
    border-radius: $border-radius;
    display: flex;
    flex: 1 1 auto;
    padding-top: 0;

    a {
        flex: 1 1 auto;
    }
}

.card-flex {
    display: flex;
    flex-direction: column;
}

.card-overview-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    flex: 1 1 auto;
}

.card-overview-content {
    align-items: unset;
}