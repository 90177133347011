@use "../settings/global" as *;
@use "../tools" as *;

.header-container {
    position: relative;
    background-color: #0067a8;
    //background-color: $color-primary-lightest;
    // .has-open-navigation & {
    // }
}

.header {
    //background-color: #0067a8;
    position: relative;
    max-width: $max-width-large;
    margin-left: auto;
    margin-right: auto;
    padding: $spacing $gutter;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;

    &.header--wrap {
        flex-flow: row wrap;
    }

    @include mq(0, $bp-mobilenav) {
        .has-open-navigation & {
            box-shadow: 0;
        }
    }

    @include mq($bp-mobilenav) {
        // flex-flow: row wrap;
        justify-content: flex-end;
        align-items: center;
        box-shadow: none;
        min-height: 0;

        //background-color: transparent;
    }
}

.header__logo {
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    padding: 2rem 0rem 0rem;
    border-radius: 0px 0px 55px 55px;
    //z-index: 9999;

    //display: block;
    //flex: 0 1 auto;
    // margin-top: 0.6rem;
    //margin-right: $gutter;
    // margin-bottom: -1.6rem;

    @include mq(0, $bp-mobilenav) {
        border-radius: 0px 0px 55px 55px;
        padding-top: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1.5rem;

        > svg,
        img {
            max-height: 7rem;
            vertical-align: unset;
        }
    }

    @include mq($bp-mobilenav) {
        top: -6rem;
        padding-top: 3rem;
        padding-right: 3rem;
        padding-bottom: 1rem;
        padding-left: 3rem;
        // margin-top: 1.2rem;
        margin-right: $gutter * 2;
        // margin-bottom: 1.8rem;
        // align-self: flex-end;

        > svg,
        img {
            // width: 30rem;

            // @include mq(1000) {
            width: 15rem;
            // }
        }
    }
}

.header__start {
    justify-content: flex-end;
    display: flex;
    // align-self: flex-end;
}

.header__start,
.header__end {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    @include mq($bp-mobilenav) {
        flex: 0 0 auto;
    }

    // @include mq(xxl) {
    //     width: 20rem;
    // }
}

.header__end {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;

    @include mq($bp-mobilenav) {
        margin-left: 0;
    }

    // .button--primary {
    //     padding: $gutter $gutter * 2;

    //     @include mq(xl) {
    //         margin-right: $spacing-large;
    //     }
    // }
}
