@use "../settings/global" as *;
@use "../tools" as *;

.sponsorcontainer {
  margin-top: $vertical-spacing * 2;
  margin-bottom: $vertical-spacing;

  @include mq(md) {
    margin-top: $vertical-spacing * 3;
    margin-bottom: $vertical-spacing * 2;
  }
}
.sponsorgrid {
  list-style: none;

  margin-left: 0;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  margin-left: auto;
  margin-right: auto;

  width: 100%;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;

  row-gap: 6rem;
  column-gap: 6rem;
}

.sponsorgrid-item {
  display: block;
  // flex: 0 1 auto;
  // width: 50%;
}

.sponsorgrid-link {
  display: block;
}

.sponsorgrid-logo {
  max-width: 20rem;
  max-height: 7rem;
}
