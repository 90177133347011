@use "../settings/global" as *;
@use "../tools/mq" as *;

.overview {
    padding-top: $vertical-spacing;
    padding-bottom: $vertical-spacing;
    height: 100%;
}

.overview__content {
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: flex-end;
    height: 100%;

    border: 0.1rem solid transparent;
    padding: $spacing-large;

    &:focus,
    &:hover {
        background-color: $white;
        border: 0.1rem solid $color-primary-light;
        border-radius: $border-radius;
    }
}

.overview__body {
    flex: 1 1 auto;
    padding-left: $gutter;

    :last-child {
        margin-bottom: 0;
    }
}

.overview__headline {
    font-family: $font-family;
    font-size: 1.8rem;
    font-weight: 700;
}

.overview__media {
    min-width: max-content;
    padding-right: $gutter;
}

// .overview__image {
//     width: 4.8rem;
//     height: 4.8rem;
// }
