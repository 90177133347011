@use "../settings/global" as *;

/// Paragraph
p {
    margin-top: 0;
    margin-bottom: $vertical-spacing;

    &:empty {
        display: none;
    }
}

.redactor {
    p:last-child {
        margin-bottom: 0;
    }
}
