@use "../settings/global" as *;

/// Mixins
@mixin visually-hidden() {
    &:not(:focus):not(:active) {
        position: absolute;

        width: 1px;
        height: 1px;
        margin: -1px;
        border: 0;
        padding: 0;

        white-space: nowrap;

        clip-path: inset(100%);
        clip: rect(0 0 0 0);
        overflow: hidden;
    }
}

/*
 * Word wrap & ellipsis mixins
 * https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
 */
@mixin word-wrap() {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

@mixin ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin icon-fontawesome($variable) {
    display: inline-block;
    font-family: $icon-material;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: inherit;
    line-height: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    content: $variable;
}

@mixin tooltip-tip {
    &::before,
    &::after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &::before {
        border-color: rgba(194, 225, 245, 0);
        border-bottom-color: $color-subsidiary-lightest;
        border-width: 8px;
        margin-left: -8px;
    }

    &::after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: $white;
        border-width: 7px;
        margin-left: -7px;
    }
}

@mixin hover-up {
    transition: transform 0.1s, box-shadow 0.1s;
    transform: translateY(0);

    &:hover {
        transform: translateY(-0.3rem);
        box-shadow: $box-shadow;

        .contrast-element & {
            box-shadow: 0 0.3rem 0.5rem rgba($color-subsidiary-light, 0.5),
                0 0 0.2rem rgba($color-subsidiary-light, 0.6);
        }
    }

    &:active {
        box-shadow: none;
        transform: translateY(0);

        .contrast-element & {
            box-shadow: none;
        }
    }
}
