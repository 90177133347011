@use "../settings/global" as *;
@use "../tools" as *;

.footer-container {
    position: relative;
    background-color: $color-primary;
    padding-top: $vertical-spacing * 5;

    @include mq(md) {
        padding-bottom: $vertical-spacing * 2;
    }
}

.footersections {
    position: relative;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $gutter;
    padding-right: $gutter;
    display: flex;
    flex-flow: column nowrap;

    @include mq(lg) {
        flex-flow: row wrap;
    }
    @include mq(0, lg) {
        flex-direction: column-reverse;
    }

}

.footersection {
    padding-top: $spacing * 2;
    padding-bottom: $spacing * 2;
    width: 25%;
    @include mq(0, lg) {
        padding-bottom: 0;
        width: 100%;
    }
}

.footersection-media {
    padding-right: $spacing-large;

    & + .footersection-navigation {
        @include mq(0, lg) {
            margin-top: $vertical-spacing * 4;
        }
    }
}

.footersection__socials {
    margin-top: 10rem;
    @include mq(0, lg) {
        margin-top: 0;
    }
}

.footersection__logo {
    position: absolute;
    background: #fff;
    top: 0;
    right: 50%;
    transform: translate(50%,-50%);
    padding: 2rem 2rem;
    border-radius: 0px 55px 55px 55px;

    @include mq(0, $bp-mobilenav) {
        > svg,
        img {
            max-height: 10rem;
        }
    }

    @include mq($bp-mobilenav) {
        top: -10rem;
        left: 0;
        right: unset;
        transform: none;
        padding: 3rem 3rem 1rem;

        > svg,
        img {
            width: 15rem;
        }
    }
}

 .footersection-navigation {
     display: flex;
     flex-wrap: wrap;
     justify-content: start;
     padding-left: 3rem;

     @include mq(0, lg) {
         padding-left: 0;
         justify-content: center;
         margin-top: $spacing-large * 2;

     }
 }

.footersection-social {
    display: flex;
    justify-content: center;
}

 .footersection-contactinfo {
     margin-top: 6rem;
     width:100%;

     @include mq($bp-mobilenav) {
         margin-top: 10rem;
         width: 20%;
     }
 }

.footersection__item {
    flex: 1 1 auto;
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
    padding-right: $spacing-large;

    @include mq(md) {
        padding-right: $spacing-large;
    }
}

.footersection__headline {
    font-family: $font-family;
    font-size: 2.2rem;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    color: $color-secondary;
    margin-bottom: $vertical-spacing * 2;

    @include mq($bp-mobilenav) {
        text-align: left;
    }
}

.footersection__subtitle {
    font-size: 2rem;
    font-weight: 700;
    color: $white;
    margin-bottom: $spacing-large;
}

.footernav {
    @include mq(0, lg) {
        display: flex;
        justify-content: center;
    }
}

.footernav__list {
    list-style: none;
    margin-left: 0;
    margin-bottom: $vertical-spacing;
}

.footernav__item {
    & + & {
        margin-top: $spacing-small;
    }

    @include mq(0, lg) {
        text-align: center;
    }
}

.footernav__link {
    font-family: $font-family;
    font-size: 1.6rem;
    font-weight: 500;
    color: $white;
    text-decoration: underline;

    &:hover,
    &:focus {
        color: $color-secondary;
        text-decoration: underline;
    }

    &:active {
        color: transparent;
        text-decoration: none;
    }

    @include mq(0, lg) {
        text-align: center;
    }
}

.footersection__social {
    .contactinfo__link + .contactinfo__link {
        margin-top: $spacing;
    }
}

.footer__section {
    flex: 1 1 33.3333%;
    min-width: 200px;
}

