@use '../settings/global' as *;
@use "../tools" as *;


/// Headings
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    display: block;
    margin-top: 0;
    margin-bottom: $vertical-spacing;
    padding: 0;
    line-height: 1.05;

    // anchors in headings should remain like headings, not anchors
    a {
        color: inherit;
        text-decoration: inherit;
        font-weight: inherit;

        &:hover, &:focus {
            color: inherit;
            text-decoration: none;
            border-bottom: 0;
        }
    }

    img {
        vertical-align: baseline;
    }

    // &.text-center {
    //     text-align: center;
    // }
}

h1, .h1 {
    font-size: 3.7rem;
    font-family: $font-family-secondary;
    font-weight: 600;
    // font-style: italic;
    color: $color-text;
    // text-transform: uppercase;

    @include mq(md){
        font-size: 3.7rem;
    }
}

h2, .h2, .fui-heading-h2 {
    font-family: $font-family-secondary;
    font-size: 3.4rem;
    font-weight: 600;
    // font-style: italic;
    color: $color-text;
    text-transform: none;
    margin-bottom: $vertical-spacing *2;

    @include mq(md){
        font-size: 4rem;
    }
}

h3, .h3, .fui-heading-h3 {
    font-family: $font-family-secondary;
    font-size: 3.1rem;
    font-weight: 600;
    // font-style: italic;
    color: $color-text;
    text-transform: none;

    @include mq(md){
        font-size: 3.1rem;
    }
}

h4, .h4, .fui-heading-h4 {
    font-family: $font-family-secondary;
    font-size: 2.7rem;
    font-weight: 600;
    // font-style: italic;
    color: $color-text;
    text-transform: none;

    @include mq(md){
        font-size: 2.7rem;
    }
}

h5, .h5, .fui-heading-h5 {
    font-family: $font-family-secondary;
    font-size: 2.4rem;
    font-weight: 600;
    // font-style: italic;
    color: $color-text;
    text-transform: none;

    @include mq(md){
        font-size: 2.4rem;
    }
}

h6, .h6, .fui-heading-h6 {
    font-family: $font-family-secondary;
    font-size: 2rem;
    font-weight: 600;
    // font-style: italic;
    color: $color-text;
    text-transform: none;

    @include mq(md){
        font-size: 2rem;
    }
}
